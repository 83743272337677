<template>

  <div class="content-body content-body--senior-call">
    <div
      v-if="errors != ''"
      class="alert alert-danger mt-1 p-1"
    >
      {{ errors }}
    </div>
    <div class="row">
      <form
        id="edit-form"
        action=""
      >
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h3 class="cart-title">
                <a data-action="collapse">Check the following levels</a>
              </h3>
              <div class="heading-elements">
                <ul class="list-inline mb-0">
                  <li>
                    <a data-action="collapse"><i data-feather="chevron-down" /></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card-content collapse show">
              <div class="card-body">
                <div class="table-responsive-sm">
                  <table class="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Following levels</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="child, index in childrens"
                        :key="index"
                      >
                        <td>{{ child.name }}</td>
                        <td>
                          <v-select
                            v-model="child.newParent"
                            label="name"
                            :options="items"
                            :get-option-key="option => option.id"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

    </div>

    <div class="page-actions">
      <button
        v-if="!sending"
        id="type-success"
        class="btn btn-success"
        @click="save"
      >
        <i data-feather="save" />
        <span class="ms-25 align-middle">Save and Exit</span>
      </button>
      <button
        v-else
        class="btn btn-outline-success"
        type="button"
        disabled=""
      >
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
        <span class="ms-25 align-middle">Saving...</span>
      </button>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'

export default {
  components: {
    vSelect,
  },
  props: {
    parentId: { type: Number, required: true, default: 0 },
    childrens: { type: Array, required: true, default: () => [] },
    selectedLevel: { type: Object, required: true, default: () => {} },
  },
  data() {
    return {
      sending: false,
      errors: '',
    }
  },
  computed: {
    ...mapGetters({
      items: 'erc/itemsFrom',
    }),
  },
  async mounted() {
    await this.$store.dispatch('erc/fetchItemsFrom', this.parentId)
  },
  methods: {
    async save() {
      this.sending = true
      try {
        // if (this.selectedLevel.id) {
        await this.$store.dispatch('erc/updateParentRelations', this.childrens)
        // }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '') {
        Vue.swal('The erc has been changed correctly!', '', 'success').then(async () => {
          if (this.selectedLevel.users.length > 0) {
            await this.$store.dispatch('erc/setAffectedUsers', this.selectedLevel.users)
            this.$router.push({ name: 'admin.erc.affected-users' })
          } else {
            this.$router.push({ name: 'admin.erc.index' })
          }
        })
      } else {
        // Vue.swal
      }

      this.sending = false
    },
  },

}
</script>

<style>

</style>
