<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">Reorganize ERC<br>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to types of personnel list"
              @click="$router.push({name: 'admin.erc.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Senior call settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div
          v-if="errors != ''"
          class="alert alert-danger mt-1 p-1"
        >
          {{ errors }}
        </div>
        <div class="row">
          <form
            id="edit-form"
            action=""
          >
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="cart-title">
                    <a data-action="collapse">Content</a>
                  </h3>
                  <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li>
                        <a data-action="collapse"><i data-feather="chevron-down" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-content collapse show">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="col-sm-12">
                          <div class="mb-1 form-group">
                            <label
                              for=""
                              class="form-label"
                            >{{ labels['content.level_1'] }}</label>
                            <div
                              class="input-group input-group-merge"
                            >
                              <v-select
                                label="name"
                                class="form-select-custom"
                                :options="levels1"
                                :get-option-key="option => option.id"
                                :disabled="editing"
                                @input="getChildrens($event, 'level1')"
                              /><span
                                class="input-group-text cursor-pointer"
                                :class="levelBgColor == 1 ? 'bg-primary' : ''"
                                :disabled="canCheck"
                                @click="showReorganize(1)"
                              ><i data-feather="edit" /></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="mb-1 form-group">
                            <label
                              for=""
                              class="form-label"
                            >{{ labels['content.level_2'] }}</label>
                            <div
                              class="input-group input-group-merge"
                            >
                              <v-select
                                label="name"
                                class="form-select-custom"
                                :options="levels2"
                                :get-option-key="option => option.id"
                                :disabled="editing"
                                @input="getChildrens($event, 'level2')"
                              /><span
                                class="input-group-text cursor-pointer"
                                :class="levelBgColor == 2 ? 'bg-primary' : ''"
                                @click="showReorganize(2)"
                              ><i data-feather="edit" /></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="mb-1 form-group">
                            <label
                              for=""
                              class="form-label"
                            >{{ labels['content.level_3'] }}</label>
                            <div
                              class="input-group input-group-merge"
                            >
                              <v-select
                                label="name"
                                class="form-select-custom"
                                :options="levels3"
                                :get-option-key="option => option.id"
                                :disabled="editing"
                                @input="getChildrens($event, 'level3')"
                              /><span
                                class="input-group-text cursor-pointer"
                                :class="levelBgColor == 3 ? 'bg-primary' : ''"
                                @click="showReorganize(3)"
                              ><i data-feather="edit" /></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :style="{ visibility: editing ? 'visible' : 'hidden' }"
                        class="col-sm-6"
                      >
                        <div
                          class="col-sm-12"
                        >
                          <div class="mb-1 form-group">
                            <label
                              for=""
                              class="form-label d-block"
                            >Level {{ selectedNumLevel }} - Old name<a
                              class="float-end text-danger"
                              @click="showReorganize"
                            ><i data-feather="x" /> Cancel</a></label>
                            <input
                              v-if="selectedLevel"
                              v-model="selectedLevel.name"
                              class="form-control"
                              type="text"
                              disabled
                            >
                          </div>
                        </div>
                        <div
                          v-for="(new_erc, index) in new_ercs"
                          :key="index"
                          class="col-sm-12"
                        >
                          <div class="mb-1 form-group">
                            <label
                              for=""
                              class="form-label d-block"
                            >Level {{ selectedNumLevel }} - New name<a
                              v-if="index == newErcsLength"
                              class="float-end text-info"
                              @click="addNewName"
                            ><i data-feather="plus" /> Add new</a>
                              <a
                                v-else
                                class="float-end text-warning"
                                @click="removeNewName(index)"
                              ><i data-feather="x" /> Remove</a>
                            </label>
                            <input
                              v-if="selectedLevel"
                              v-model="new_erc.name"
                              class="form-control"
                              type="text"
                              :disabled="canCheck"
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

        </div>

        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            :disabled="canCheck"
            @click="save"
          >
            <i data-feather="save" />
            <span class="ms-25 align-middle">Save and review</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>

      </div>
      <CheckFollowinLevels
        v-if="canCheck && selectedLevel"
        :parent-id="selectedLevel.id"
        :childrens="selectedLevel.childrens ? selectedLevel.childrens : []"
        :selected-level="selectedLevel"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import CheckFollowinLevels from './components/CheckFollowinLevels.vue'

export default {
  components: {
    vSelect,
    CheckFollowinLevels,
  },
  data() {
    return {
      sending: false,
      errors: '',
      levels2: [],
      levels3: [],
      selectedLevel: null,
      selectedNumLevel: null,
      level1: null,
      level2: null,
      level3: null,
      editing: false,
      new_ercs: [{
        name: '',
      }],
      newErcsLength: 0,
      levelBgColor: 0,
      canCheck: false,
    }
  },
  computed: {
    ...mapGetters({
      levels1: 'erc/items',
      labels: 'sectionLabels/itemLabels',
    }),
  },
  async mounted() {
    this.$store.dispatch('sectionLabels/fetchLabels', 'masters.ercs_reorganyze')
    await this.$store.dispatch('erc/filter', { level1: 'yes' })
  },
  methods: {
    async save() {
      this.sending = true
      try {
        this.selectedLevel.new_ercs = this.new_ercs
        if (this.selectedLevel.id) {
          await this.$store.dispatch('erc/update', { id: this.selectedLevel.id, data: this.selectedLevel })
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.selectedLevel.id) {
        Vue.swal('The erc has been changed correctly!', '', 'success').then(async () => {
          if (this.newErcsLength > 0) {
            this.canCheck = true
          } else {
            this.$router.push({ name: 'admin.erc.index' })
          }
        })
      } else {
        // Vue.swal
      }

      this.sending = false
    },
    getChildrens($event, $parent) {
      switch ($parent) {
        case 'level1':
          this.level1 = $event
          this.levels2 = $event ? $event.childrens : []
          break
        case 'level2':
          this.level2 = $event
          this.levels3 = $event ? $event.childrens : []
          break
        case 'level3':
          this.level3 = $event
          break
        default:
      }
    },
    showReorganize($numLevel) {
      if (!this.canCheck) {
        if (!this.editing) {
          switch ($numLevel) {
            case 1:
              this.selectedLevel = this.level1 ? this.level1 : null
              this.levelBgColor = 1
              break
            case 2:
              this.selectedLevel = this.level2 ? this.level2 : null
              this.levelBgColor = 2
              break
            case 3:
              this.selectedLevel = this.level3 ? this.level3 : null
              this.levelBgColor = 3
              break
            default:
          }
          if (this.selectedLevel) {
            this.editing = true
            this.selectedNumLevel = $numLevel
          } else {
            this.levelBgColor = 0
          }
        } else {
          this.editing = false
          this.levelBgColor = 0
        }
      }
    },
    addNewName() {
      if (!this.canCheck) {
        this.new_ercs.push({ name: '' })
        this.newErcsLength = this.new_ercs.length - 1

        setTimeout(() => {
          // eslint-disable-next-line no-undef
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 200)
      }
    },
    removeNewName(index) {
      if (!this.canCheck) {
        this.new_ercs.splice(index, 1)
        this.newErcsLength = this.new_ercs.length - 1

        setTimeout(() => {
          // eslint-disable-next-line no-undef
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 200)
      }
    },
  },

}
</script>
